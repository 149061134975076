<template>
  <div :id="id" aria-hidden="true" class="ui-modal" tabindex="-1">
    <div class="ui-modal-container" :class="classContainer">
      <div class="ui-modal-box md:py-8 md:px-10">
        <slot name="modal-slot"></slot>
      </div>
    </div>
    <div class="ui-modal-backdrop" @click="close"></div>
  </div>
</template>
<script>
export default {
  name: 'uiModal',
  props: {
    id: {
      type: String,
      default: null,
      required: true,
    },
    classContainer: {
      type: String,
      default: null,
      required: false,
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>
