<template>
  <div class="container">
    <ui-modal v-show="true" id="defaultModal" classContainer="max-w-[500px]">
      <template #modal-slot>
        <h3 class="mb-5 w-full pr-8 text-2xl font-bold">Вход</h3>
        <form
          class="grid-rows-12 grid grid-flow-col items-end gap-4"
          v-on:submit.prevent="validateBeforeSubmit"
        >
          <div class="col-span-10">
            <div class="ui-input">
              <label class="ui-input__label" for="enter_password">Пароль</label>
              <div class="ui-input__field">
                <input
                  id="enter_password"
                  class="ui-input__field--input"
                  :type="fieldType"
                  name="password"
                  v-model.trim="password"
                />
                <button
                  @click.prevent="togglePassword"
                  class="eye ui-input__field--btn bg-[url('./img/ic-eye-24.svg')]"
                ></button>
              </div>
            </div>
          </div>
          <div class="col-span-2">
            <button class="ui-btn ui-btn-primary" type="submit">
              <span
                class="ui-btn__ic m-0 bg-[url('./img/ic-enter-24.svg')]"
              ></span>
            </button>
          </div>
        </form>
        <span v-if="error" class="ui-text-invalid block pt-3">{{ error }}</span>
      </template>
    </ui-modal>
  </div>
</template>
<script>
import storageHelper from 'storage-helper'
import router from '@/router'
import uiModal from '@/components/ui/uiModal.vue'
export default {
  name: 'ProtectedPage',
  components: {
    'ui-modal': uiModal,
  },
  data() {
    return {
      error: null,
      password: null,
      fieldType: 'password',
      passwordValue: '',
    }
  },
  methods: {
    validateBeforeSubmit() {
      if (this.password === 'dswms') {
        this.error = false
        storageHelper.setItem('user-password', this.password)
        router.push('/')
      } else {
        this.error = 'Неверный пароль'
      }
    },
    update(item, value) {
      value = event.target.value
      item.passwordValue = value
    },
    togglePassword() {
      this.fieldType = this.fieldType === 'password' ? 'text' : 'password'
    },
  },
}
</script>
<style scoped></style>
