<template>
  <div
    id="app"
    :class="{
      light: lightMode,
      dark: darkMode,
      cupcake: cupcakeMode,
      forest: forestMode,
      dracula: draculaMode,
    }"
  >
    <div class="bg-[var(--b2)]">
      <div class="grid grid-cols-12">
        <div class="col-span-12 px-10 py-6 md:col-span-3">
          <side-bar
            @chooseLightTheme="chooseLightTheme()"
            @chooseDarkTheme="chooseDarkTheme()"
            @chooseCupcakeTheme="chooseCupcakeTheme()"
            @chooseForestTheme="chooseForestTheme()"
            @chooseDraculaTheme="chooseDraculaTheme()"
          />
        </div>
        <div class="col-span-12 md:col-span-9">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SideBar from '@/components/SideBar'

export default {
  name: 'HomeView',
  components: {
    'side-bar': SideBar,
  },
  data() {
    return {
      lightMode: true,
      darkMode: false,
      cupcakeMode: false,
      forestMode: false,
      draculaMode: false,
      currentTime: new Date(),
    }
  },
  methods: {
    chooseLightTheme() {
      this.lightMode = true
      this.darkMode = false
      this.cupcakeMode = false
      this.forestMode = false
      this.draculaMode = false
      clearInterval(this.updateTime)
    },
    chooseDarkTheme() {
      this.lightMode = false
      this.darkMode = true
      this.cupcakeMode = false
      this.forestMode = false
      this.draculaMode = false
      clearInterval(this.updateTime)
    },
    chooseCupcakeTheme() {
      this.lightMode = false
      this.darkMode = false
      this.cupcakeMode = true
      this.forestMode = false
      this.draculaMode = false
      clearInterval(this.updateTime)
    },
    chooseForestTheme() {
      this.lightMode = false
      this.darkMode = false
      this.cupcakeMode = false
      this.forestMode = true
      this.draculaMode = false
      clearInterval(this.updateTime)
    },
    chooseDraculaTheme() {
      this.lightMode = false
      this.darkMode = false
      this.cupcakeMode = false
      this.forestMode = false
      this.draculaMode = true
      clearInterval(this.updateTime)
    },
    updateTime() {
      this.currentTime = new Date()
      const currentHour = this.currentTime.getHours()
      currentHour >= 8 && currentHour < 20
        ? (this.darkMode = false)
        : (this.darkMode = true)
    },
  },
  created() {
    this.updateTime()
    // setInterval(this.updateTime, 1000)
  },
  destroyed() {
    clearInterval(this.updateTime)
  },
}
</script>
