import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    showToasts: false,
    counter: 0,
  },
  getters: {
    COUNTER: (state) => {
      return state.counter
    },
  },
  mutations: {
    increment: (state) => (state.counter === 10 ? 10 : state.counter++),
    decrement: (state) => (state.counter === 0 ? 0 : state.counter--),
  },
})

export default store
