<template>
  <div class="h-full min-h-screen border-l border-[var(--b4)] px-10 pt-8 pb-7">
    <h3>Дизайн систем WMStudio</h3>
    <p>
      <big
        >Это набор элементов, стандартов и правил, упрощающих проектирование и
        разработку на всех этапах создания сайта.</big
      >
    </p>
    <br />
    <p>Для работы нужно:</p>
    <ol>
      <li>
        подключить CSS-фреймворк
        <a target="_blank" href="https://tailwindcss.com">tailwindcss</a>
      </li>
      <li>
        подключить
        <a
          target="_blank"
          href="https://github.com/tailwindlabs/prettier-plugin-tailwindcss"
          >Prettier</a
        >
        для Tailwind CSS, для автоматической сортировки классов в соответствии с
        рекомендуемым порядком
      </li>
      <li>
        подключить готовый настроенный файл
        <a href="/../tailwind.config.js" download>tailwind.config.js</a>, при
        необходимости настройки можно изменить
      </li>
      <li>
        подключить
        <a href="https://disk.yandex.ru/d/GHezA-HbzUY4pA" target="_blank"
          >иконки</a
        >
      </li>
      <li><a href="#">конфиг</a> для вебшторма</li>
    </ol>
    <br />
    <p>Рекомендация</p>
    <ul>
      <li>
        для быстрого старта используй "Быстрый поиск" классов на сайте
        <a target="_blank" href="https://tailwindcss.com/docs/installation"
          >tailwindcss</a
        >
      </li>
    </ul>
    <br />
    <br />
    <h3>Добавить дизайн:</h3>
    <ol>
      <li>
        <ol>
          <li>Carousel</li>
          <li>Video</li>
          <li>spinner</li>
          <li>Speed Dial</li>
          <li>Timeline</li>
        </ol>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
}
</script>
